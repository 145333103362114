import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Berita from '../views/berita/Berita.vue'
import isi_berita from '../views/berita/isi_berita.vue'

// PROFILE
import struktur_organisasi from '../views/profile/struktur_organisasi.vue'
import tugas_pokok from '../views/profile/tugas_pokok.vue'
import tujuan_sasaran from '../views/profile/tujuan_sasaran.vue'
import visi_misi from '../views/profile/visi_misi.vue'

// UNIT KERJA
import sekretariat from '../views/unit_kerja/sekretariat.vue'
import sarana_prasarana from '../views/unit_kerja/sarana_prasarana.vue'
import pencegahan from '../views/unit_kerja/pencegahan.vue'
import pemadaman_penyelamatan from '../views/unit_kerja/pemadaman_penyelamatan.vue'

// DOKUMEN LAINNYA
import damkar_dokumen from '../views/damkar_dokumen.vue'

// GALERI
import foto from '../views/galeri/foto.vue'
import video from '../views/galeri/video.vue'

// LAYANAN
import telepon_penting from '../views/layanan/telepon_penting.vue'
import titik_rawan_kebakaran from '../views/layanan/titik_rawan_kebakaran.vue'
import titik_rawan_bahaya from '../views/layanan/titik_rawan_bahaya.vue'
import sapras from '../views/layanan/sapras.vue'


Vue.use(VueRouter)

const routes = [

// LAYANAN
{
  path: '/telepon_penting',
  name: 'telepon_penting',
  component: telepon_penting
},
{
  path: '/titik_rawan_kebakaran',
  name: 'titik_rawan_kebakaran',
  component: titik_rawan_kebakaran
},
{
  path: '/titik_rawan_bahaya',
  name: 'titik_rawan_bahaya',
  component: titik_rawan_bahaya
},
{
  path: '/sapras',
  name: 'sapras',
  component: sapras
},



// 

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Berita',
    name: 'Berita',
    component: Berita
  },
  {
    path: '/isi_berita',
    name: 'isi_berita',
    component: isi_berita
  },
  {
    path: '/damkar_dokumen',
    name: 'damkar_dokumen',
    component: damkar_dokumen
  },
  {
    path: '/struktur_organisasi',
    name: 'struktur_organisasi',
    component: struktur_organisasi
  },
  {
    path: '/tugas_pokok',
    name: 'tugas_pokok',
    component: tugas_pokok
  },
  {
    path: '/tujuan_sasaran',
    name: 'tujuan_sasaran',
    component: tujuan_sasaran
  },
  {
    path: '/visi_misi',
    name: 'visi_misi',
    component: visi_misi
  },

// 
{
  path: '/sekretariat',
  name: 'sekretariat',
  component: sekretariat
},
{
  path: '/sarana_prasarana',
  name: 'sarana_prasarana',
  component: sarana_prasarana
},
{
  path: '/pencegahan',
  name: 'pencegahan',
  component: pencegahan
},
{
  path: '/pemadaman_penyelamatan',
  name: 'pemadaman_penyelamatan',
  component: pemadaman_penyelamatan
},

// 
{
  path: '/foto',
  name: 'foto',
  component: foto
},
{
  path: '/video',
  name: 'video',
  component: video
},


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
