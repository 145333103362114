import { render, staticRenderFns } from "./titik_rawan_kebakaran.vue?vue&type=template&id=b4dbda08&"
import script from "./titik_rawan_kebakaran.vue?vue&type=script&lang=js&"
export * from "./titik_rawan_kebakaran.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports