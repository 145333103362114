<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2>Bidang<span> Pencegahan</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 ab-sec">
                    <div class="col-md-12">
                        <p  v-html="uraian"></p>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    //const URL_APP = 'http://localhost:5005/'; 
const URL_APP = 'https://server-web.konaweselatankab.go.id/';	
    const URL = URL_APP + "api/v1/publish_damkar_unit_kerja/";
    

export default {  

    data() {
            return {
                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
                uraian: "",
                kategori: "Bidang Pencegahan",
            }
        },
        methods: {
        getView: function() {
        fetch(URL + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            },
            body : JSON.stringify({
                kategori: this.kategori,
            }),
        })
            .then(res => res.json())
            .then(res_data => {
            this.cek_load_data = false;

            var tes = res_data[0];
            this.uraian = tes.uraian;
            });
        },

    // ================== PAGINASI ====================
        alertku: function(type, title) {
        this.$swal({
            type: type,
            title: title,
            showConfirmButton: false,
            timer: 800
        });
        },

        btn_prev : function(){
                this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){

            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){

            this.page_first = 1;
            this.getView();
        },
        onFileSelected: function(event) {
        this.form.file = event.target.files[0];
        },
        convert_tgl: function(dateString) {
        var date = new Date(dateString);
        return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
        }
    // ================== PAGINASI ====================
    },
    // =============================================================== METHODS =========================================================================

    mounted() {

        this.getView();



        }


}
</script>