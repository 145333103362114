<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2>Visi & <span>Misi</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 ab-sec">
                    <div class="col-md-12">
                        <p  v-html="isi"></p>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    //const URL_APP = 'http://localhost:5005/';    
const URL_APP = 'https://server-web.konaweselatankab.go.id/';	
    const UNIT_KERJA = 'zghjglbHFG'; // DAMKAR
    const URL = URL_APP + "api/v1/publish_damkar_visi_misi/";
    

export default {  

  data() {
			return {
                id: "",
                
                unit_kerja : UNIT_KERJA,
            
                isi: "",

			}
		},

    methods: {
            
			getIsi: function() {
                fetch(URL + "view", {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },

                })
                    .then(res => res.json())
                    .then(res_data => {

                        console.log("hasil ", res_data);
                        var isi = res_data[0];

                        this.isi = isi.uraian;

                    });
            },
            

        },
		mounted() {

            this.getIsi();
            

		},


}
</script>